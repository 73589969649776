/* ID Selectors */
#banner {
  background: transparent url(../../images/banner.jpg) no-repeat center center /
    cover;
  height: 200px;
  margin-bottom: 120px;
}

#circle-image {
  border-radius: 50%;
  width: 250px;
  position: absolute;
  left: 20px;
  top: 50px;
  border: 2px solid #fff;
}

#main {
  background-color: #012a4a;
  color: #fff;
}

#main-header {
  position: absolute;
  left: 260px;
  top: 250px;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  #circle-image {
    width: 150px;
    top: 30px;
  }

  #banner {
    background: transparent url(../../images/banner.jpg) no-repeat center center /
      cover;
    height: 120px;
    margin-bottom: 80px;
  }

  #main-header {
    position: absolute;
    left: 170px;
    top: 135px;
  }
}
