/* Element selectors */

a {
  color: #a9d6e5;
}

a:hover {
  color: #89c2d9;
  text-decoration: none;
}

body {
  margin-bottom: 40px;
  font-family: "Courier New", Courier, monospace;
  background-image: linear-gradient(90deg, #81c0db,#164357 );
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: "Amatic SC", "Courier New", Courier, monospace;
  font-weight: 700;
}

html {
  position: relative;
  min-height: 100%;
}
