.animate-card {
  position: relative;
  animation: animatecard 2s;
} @keyframes animatecard {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.card-custom {
    margin: 20px;
    width: 250px;
    background-color: #01497c;
  }

  .card-img-top {
    max-height: 180px;
    object-fit: cover;
    object-position: top;
  }

  .project-card:hover {
    cursor: pointer;
  }
