.project-modal {
    z-index: 3;
    padding-top: 25px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }
  .project-modal-content {
    margin: auto;
    background-color: #01497c;
    position: relative;
    padding: 0;
    outline: 0;
    width: 80%;
    max-width: 600px;
    color: #FFF;
    border-radius: 10px;
  }

  .animate-top {
    position: relative;
    animation: animatetop 1s;
  }
  @keyframes animatetop {
    from {
      left: -300px;
    }
    to {
      left: 0;
    }
  }
