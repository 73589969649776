.sidebar {
  height: 100%;
  position: fixed;
  width: 200px;
  z-index: 1;
  top: 0;
  background-color: #01497c;
  overflow-x: hidden;
  padding-top: 20px;
  animation: slidein .75s;
} @keyframes slidein {
  from {
    left: -250px;
  }
  to {
    left: 0px;
  }
}

.sidebar .list-group-item {
  background-color: #01497c;
}

.sidebar-links {
  width: 180px;
  margin-left: 10px;
}

#close-menu {
  float: right;
  margin-right: 10px;
  color: #a9d6e5;
}

#close-menu:hover {
  color: #61a5c2;
}

